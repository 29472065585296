import React, { useState, useEffect } from "react";
import { useRoute } from "wouter";

const BlogPost = () => {
  const [match, params] = useRoute('/blog/:id');
  const [post, setPost] = useState(null);

  useEffect(() => {
    if (match) {
      fetch(`https://grimm.pw/api/blog/${params.id}`)
        .then((response) => response.json())
        .then((data) => setPost(data))
        .catch((error) => console.error('Error fetching data:', error));
    }
  }, [match, params.id]);

  if (!post) {
    return <div>Loading...</div>;
  }

  return (
    <>
        <title>{post.title}</title>
        <div className="blogPost">
            <div className="blogHeader">
                <h1>{post.title}</h1>
                <p>
                    {post.createdAt ? <span>Created At - {new Date(post.createdAt).toLocaleDateString()} <strong>Author:</strong> {post.author} </span> : null}
                </p>
            </div>

            <div className="blogContent">
                <p>{post.content}</p>
            </div>
        </div>
    </>
  );
};

export default BlogPost;
