import React, { useState, useEffect } from "react";
import { Link } from "wouter";

const Blog = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    fetch('https://grimm.pw/api/blog')
      .then((response) => response.json())
      .then((data) => setPosts(data))
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  return (
    <div>
      <div id='warning' className="warning">
        <img src="/images/warning.png" />
        <h2>This website is legit being developed on!</h2>
        <p>This website is a work in progress, I didnt share the link, so good job on getting here! but dont expect much, the reason is up is so I can test er out. But hey, nice to see ya, stop by every now and again to see sweet ass updates! :)</p>
        <p>Just realized that I left the link on Discord. but hey, still glad your're here!</p>
      </div>
      <h1>Blog Posts</h1>
      {posts.map((post) => (
        <div className="blogDiv">
            <Link href={`/blog/${post._id}`} style={{ textDecoration: 'none', color: 'white' }}>
                <div key={post._id} className="blogPostHome">
                    <div className="blogPostHeader">
                        <h2>
                            {post.title}
                        </h2>
                    </div>
                    <div className="blogPostContent">
                        <p>{post.content}</p>
                    </div>
                </div>
            </Link>
        </div>
      ))}
    </div>
  );
};

export default Blog;
